import { makeStyles, mergeClasses, /*Persona,*/ Image, shorthands, Text, tokens } from '@fluentui/react-components';
import { ShieldTask16Regular } from '@fluentui/react-icons';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, /*Breakpoints,*/ SharedStyles } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';

import MessageIcon from '../../../assets/images/Message.svg';
import { useChat } from '../../../libs/hooks/useChat';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        minHeight: '48px',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
        ...Breakpoints.small({
            width: '90%',
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalMNudge),
        }),
    },
    actions: {
        display: 'none',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralBackground1,
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    popoverSurface: {
        display: 'none',
    },
    selected: {
        backgroundColor: '#576DDD',
        boxShadow: 'rgb(255, 255, 255) 4px 0px 0px 0px inset',
    },
    disabled: {
        cursor: 'not-allowed',
        '& > *': {
            cursor: 'not-allowed',
        },
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hoverActions: {
        display: 'flex',
    },
    itemArea: {
        display: 'flex',
    },
    hovered: {
        width: '80%',
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    timestamp: number | null;
    hasMessages: boolean;
    preview: string | undefined;
    botProfilePicture: string;
    isSelected: boolean;
    disabled: boolean;
    setDisabled: (value: boolean) => void;
}

export const ChatListItem: FC<IChatListItemProps> = ({
    id,
    header,
    timestamp,
    hasMessages,
    preview = 'Click to start the chat',
    /*botProfilePicture,*/
    isSelected,
    disabled,
    setDisabled,
}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const { features } = useAppSelector((state: RootState) => state.app);

    const chat = useChat();

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
    // const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;

    const [editingTitle, setEditingTitle] = useState(false);
    const [showActions, setShowActions] = useState(false);

    const onClick = (_ev: any) => {
        dispatch(setSelectedConversation(id));

        if (!hasMessages) {
            setDisabled(true);
            void Promise.all([
                chat
                    .loadChatById(id, conversations)
                    .then(() => {
                        setDisabled(false);
                    })
                    .catch(() => {
                        // setAppState(AppState.ErrorLoadingChats);
                    }),
            ]);
        }
    };

    const time = timestamp ? timestampToDateString(timestamp) : '';
    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected, disabled ? classes.disabled : '')}
            onMouseEnter={() => {
                setShowActions(true);
            }}
            onMouseLeave={() => {
                setShowActions(false);
            }}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            <div
                className={mergeClasses(
                    classes.itemArea,
                    showActions && !editingTitle ? classes.hovered : '',
                    isSelected && !editingTitle ? classes.hovered : '',
                )}
                onClick={onClick}
            >
                <Image src={MessageIcon} style={{ marginRight: '10px', width: '16px' }} color={'#fff'} />
                {!editingTitle && (
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text className={classes.title} title={header}>
                                {header}
                                {features[FeatureKeys.AzureContentSafety].enabled && (
                                    <ShieldTask16Regular className={classes.protectedIcon} />
                                )}
                            </Text>
                            {!features[FeatureKeys.SimplifiedExperience].enabled && (
                                <Text className={classes.timestamp} size={300}>
                                    {time}
                                </Text>
                            )}
                        </div>
                        {showPreview && (
                            <>
                                {
                                    <Text id={`message-preview-${id}`} size={200} className={classes.previewText}>
                                        {preview}
                                    </Text>
                                }
                            </>
                        )}
                    </div>
                )}
            </div>
            {!editingTitle && (isSelected || showActions) && !disabled && (
                <div className={mergeClasses(classes.actions, classes.hoverActions)}>
                    <ListItemActions
                        chatId={id}
                        onEditTitleClick={() => {
                            setEditingTitle(true);
                        }}
                    />
                </div>
            )}
            {editingTitle && (
                <EditChatName
                    name={header}
                    chatId={id}
                    exitEdits={() => {
                        setEditingTitle(false);
                    }}
                />
            )}
        </div>
    );
};
