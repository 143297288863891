import { makeStyles, shorthands } from '@fluentui/react-components';
import { Drawer } from '@fluentui/react-drawer';
import { ChatList } from './chat/chat-list/ChatList';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { setIsOpenMobileMenu } from '../redux/features/app/appSlice';
import { RootState } from '../redux/app/store';

const useStyles = makeStyles({
    root: {
        // ...shorthands.border("2px", "solid", "#ccc"),
        ...shorthands.overflow('hidden'),
        display: 'flex',
        backgroundColor: '#fff',
    },

    drawer: {
        backgroundColor: '#030F2B',
        position: 'absolute',
        top: '80px',
        left: '0',
        height: 'calc(100% - 80px)',
        zIndex: '1001',
        ...shorthands.border('0'),
        width: '100%',
        maxWidth: '285px',
    },

    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '1000',
    },
});

const DrawerMenu = () => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={styles.root}>
            {isOpenMobileMenu && <div className={styles.backdrop}></div>}
            <Drawer
                type="inline"
                separator
                open={isOpenMobileMenu}
                className={styles.drawer}
                onOpenChange={(_: any, { open }: { open: boolean }) => {
                    dispatch(setIsOpenMobileMenu(open));
                }}
            >
                {/*<Button*/}
                {/*    appearance="subtle"*/}
                {/*    aria-label="Close"*/}
                {/*    icon={<Dismiss24Regular />}*/}
                {/*    onClick={() => {dispatch(setIsOpenMobileMenu(false))}}*/}
                {/*/>*/}
                <ChatList />
            </Drawer>
        </div>
    );
};

export default DrawerMenu;
