import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { useAppSelector } from '../../../../redux/app/hooks';
// import { Delete16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IEditChatNameProps {
    chatId: string;
}

const DeleteIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.70962 3.8653C7.55649 4.08406 7.5 4.36293 7.5 4.54286H6.5C6.5 4.20849 6.59351 3.71594 6.89038 3.29184C7.20556 2.84159 7.73102 2.5 8.5 2.5C9.26898 2.5 9.79444 2.84159 10.1096 3.29184C10.4065 3.71594 10.5 4.20849 10.5 4.54286H9.5C9.5 4.36293 9.44351 4.08406 9.29038 3.8653C9.15556 3.6727 8.93102 3.5 8.5 3.5C8.06898 3.5 7.84444 3.6727 7.70962 3.8653ZM5.0511 6.05714H4.5V5.05714H12.5V6.05714H11.9489L11.1989 13H5.8011L5.0511 6.05714ZM6.05692 6.05714L6.6989 12H10.3011L10.9431 6.05714H6.05692Z"
                fill="white"
            />
        </svg>
    );
};

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId }) => {
    const classes = useClasses();
    const chat = useChat();

    const { conversations } = useAppSelector((state) => state.conversations);
    const chatName = getFriendlyChatName(conversations[chatId]);

    const onDeleteChat = () => {
        void chat.deleteChat(chatId);
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button icon={<DeleteIcon />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete chat: {chatName}?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete the chat, and any associated resources and memories.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onDeleteChat}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
