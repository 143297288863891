import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import config from './okta/config';
import AppRoutes from './components/Routes';
import { FeatureKeys } from './redux/features/app/AppState';
import { semanticKernelDarkTheme, semanticKernelLightTheme } from './styles';
import { FluentProvider /*Subtitle1*/ } from '@fluentui/react-components';
import { useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';

const oktaAuth = new OktaAuth(config.oidc);

const useRestoreOriginalUri = () => {
    const navigate = useNavigate();

    return (_oktaAuth: OktaAuth, originalUri: string | undefined) => {
        navigate(toRelativeUrl(originalUri ?? '/', window.location.origin));
    };
};

const App: React.FC = () => {
    const restoreOriginalUri = useRestoreOriginalUri();
    const { features } = useAppSelector((state: RootState) => state.app);

    return (
        <FluentProvider
            className="app-container"
            theme={features[FeatureKeys.DarkMode].enabled ? semanticKernelDarkTheme : semanticKernelLightTheme}
        >
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <AppRoutes />
            </Security>
        </FluentProvider>
    );
};

export default App;
