import React, { useEffect } from 'react';
import { Button, Image, makeStyles, shorthands } from '@fluentui/react-components';
// import Typed from 'react-typed';
import logo from '../../assets/images/Logo.svg';
import screen_0 from '../../assets/images/screen-1.png';
import screen_1 from '../../assets/images/screen-2.png';
import screen_2 from '../../assets/images/screen-3.png';
import screen_3 from '../../assets/images/screen-4.png';
import screen_4 from '../../assets/images/screen-5.png';
import screen_5 from '../../assets/images/screen-6.png';
import screen_6 from '../../assets/images/screen-7.png';
import { Breakpoints } from '../../styles';
import { useOktaAuth } from '@okta/okta-react';

const useClasses = makeStyles({
    root: {
        display: 'grid',
        backgroundColor: '#030F2B',
        height: '100vh',
        gridTemplateColumns: '70% 30%',
        ...Breakpoints.small({
            gridTemplateColumns: '100%',
        }),
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        ...shorthands.flex(1, 0, 'auto'),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'auto',
        alignSelf: 'baseline',
        marginBottom: 'auto',
        marginTop: 'auto',
        marginLeft: '30px',
        paddingBottom: '180px',
    },
    button: {
        backgroundColor: '#fff',
        color: '#1A1D20',
        width: '190px',
        height: '56px',
        marginLeft: 'unset',
        marginRight: 'unset',
        fontSize: '16px',
        fontFamily: 'LVMH Sans',
        fontStyle: 'normal',
        ...shorthands.borderRadius('0'),
    },
    imageWrap: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

const images = [screen_0, screen_2, screen_1, screen_3, screen_4, screen_5, screen_6];

const sentences = [
    {
        start: 'Make',
        end: 'a synthesis in 3 bullet points of this document.',
    },
    {
        start: 'Translate',
        end: 'this text in english',
    },
    {
        start: 'Help me',
        end: 'prepare an interview for this job position',
    },
    {
        start: 'Generate',
        end: 'some SQL code to…',
    },
    {
        start: 'Explain me',
        end: 'what is this concept',
    },
    {
        start: 'Draft an email',
        end: 'requesting a deadline extension for my project',
    },
    {
        start: 'Help me',
        end: 'summarize this document',
    },
];

export const SplashScreens = () => {
    const classes = useClasses();
    const [link, setLink] = React.useState<string>('');
    const { oktaAuth } = useOktaAuth();

    const clickHandler = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        oktaAuth.signInWithRedirect();
    };

    useEffect(() => {
        const i = Math.floor(Math.random() * sentences.length);

        setLink(images[i]);
    }, []);

    useEffect(() => {
        const container = document.getElementById('container');
        let currentDiv = 0;

        function setOpacity(divs: NodeListOf<Element> | undefined, startOpacity: number) {
            if (divs) {
                for (let i = 0; i < divs.length; i++) {
                    const distance = Math.abs(currentDiv + 2 - i);
                    let opacity = startOpacity - distance * 25;
                    if (opacity < 0) opacity = 0;
                    const div = divs[i] as HTMLElement;
                    div.classList.remove('opacity-50', 'opacity-70', 'opacity-100');
                    if (opacity === 50) {
                        div.classList.add('opacity-50');
                    } else if (opacity === 75) {
                        div.classList.add('opacity-70');
                    }
                }
            }
        }

        function animateDivs() {
            const divs = container?.querySelectorAll('.box');
            if (divs) {
                setOpacity(divs, 100);
                for (let i = 0; i < divs.length; i++) {
                    if (i >= currentDiv && i <= currentDiv + 4) {
                        (divs[i] as HTMLElement).classList.remove('hide');
                    } else {
                        (divs[i] as HTMLElement).classList.add('hide');
                    }
                }
                currentDiv = (currentDiv + 1) % (divs.length - 4);
            }
        }

        setInterval(animateDivs, 3000);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <div style={{ maxWidth: 170, marginTop: 30, marginBottom: 50 }}>
                    <Image src={logo} fit="center" />
                </div>
                <div className={classes.content}>
                    {/*<PromptText firstString={firstString} prompt={prompt}/>*/}
                    <div className="container" id="container">
                        <div className="box opacity-50">
                            <p>Make</p>
                            <p>a synthesis in 3 bullet points of this document.</p>
                        </div>
                        <div className="box opacity-70">
                            <p>Translate</p>
                            <p>this text in english</p>
                        </div>
                        <div className="box">
                            <p>Help me</p>
                            <p>prepare an interview for this job position</p>
                        </div>
                        <div className="box opacity-70">
                            <p>Generate</p>
                            <p>some SQL code to…</p>
                        </div>
                        <div className="box opacity-50">
                            <p>Explain me</p>
                            <p>what is this concept</p>
                        </div>
                        <div className="box hide">
                            <p>Draft an email</p>
                            <p>requesting a deadline extension for my project</p>
                        </div>
                        <div className="box hide">
                            <p>Help me</p>
                            <p>summarize this document</p>
                        </div>
                    </div>
                    <Button
                        className={classes.button}
                        style={{ padding: 10, display: 'inline !important' }}
                        appearance="primary"
                        onClick={clickHandler}
                        data-testid="signinButton"
                    >
                        Start your conversation
                    </Button>
                </div>
            </div>
            <div className={classes.imageWrap} style={{ backgroundImage: `url(${link})` }} />
        </div>
    );
};
